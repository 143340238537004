<template>
    <div class="vx-card p-8 pt-0">
        <div v-if="!isEdit">
            <LabelForm>{{ $t('dashboard.app')}} {{ $t('shops.id') }}</LabelForm>
            <vs-input
                type="number"
                class="w-30 border-grey-light"
                v-model="scope.object_id"
                :required="true"
            />
        </div>

        <FeaturesCheckboxes
            ref="featuresCheckboxes"
            :object="scope"
        />

        <div class="flex flex-row gap-4 mt-12">
            <button class="sk-btn sk-btn-primary rounded-lg btn-border" @click="handleCancel">
                {{ $t('apps.modals.cancel')}}
            </button>
            <button class="sk-btn sk-btn-primary rounded-lg" @click="handleSave">
                {{ $t('Save')}}
            </button>
        </div>
    </div>
</template>

<script>

import loader from "@/modules/Shared/Mixins/loader";
import AuthorizationService from "@/modules/Admin/Services/AuthorizationService";
import LabelForm from "@/modules/Shared/Components/form/LabelForm.vue";
import FeaturesCheckboxes from "@/modules/Admin/Components/FeaturesCheckboxes.vue";

export default {
  name: "AppScopeForm",
  components: {FeaturesCheckboxes, LabelForm},
  mixins: [loader],
  props: {
      scope: {
          type: Object,
          required: true,
      },
      isEdit: {
          type: Boolean,
          default: false
      }
  },
  methods: {
      handleCancel() {
          this.$router.push({ name: 'admin-app-features' });
      },
      async handleSave() {
          await this.loadAndNotify(async () => {
              let response;

              const enabledFeatures = this.$refs.featuresCheckboxes.getEnabledFeatures().map(featureId => ({ id : featureId }))

              if (this.isEdit) {
                  response = await AuthorizationService.editScope(this.scope.id, {
                      enabled_features: enabledFeatures
                  })
              } else {
                  response = await AuthorizationService.createScope({
                      ...this.scope,
                      enabled_features: enabledFeatures
                  })
              }

              if (response.code !== 200) {
                  return
              }

              await this.$router.push({ name: 'admin-app-features' });
          }, false)
      }
  }
};
</script>
