<template>
  <AppScopeForm :scope="scope" :isEdit="true"/>
</template>

<script>

import AuthorizationService from "@/modules/Admin/Services/AuthorizationService";
import store from "@/modules/Shared/Store/store";
import AppScopeForm from "@/modules/Admin/Components/AppScopeForm.vue";

export default {
  name: "EditAppScopePage",
  components: {AppScopeForm},

  data() {
    return {
      scope: null,
    }
  },
  async beforeRouteEnter (to, from, next) {
      const scope = await AuthorizationService.getScope(to.params.id);
      await store.dispatch('setRouteElement', scope)
      next(vm => vm.scope = scope)
  },
};
</script>
