var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-card p-8 pt-0" },
    [
      !_vm.isEdit
        ? _c(
            "div",
            [
              _c("LabelForm", [
                _vm._v(
                  _vm._s(_vm.$t("dashboard.app")) +
                    " " +
                    _vm._s(_vm.$t("shops.id"))
                )
              ]),
              _c("vs-input", {
                staticClass: "w-30 border-grey-light",
                attrs: { type: "number", required: true },
                model: {
                  value: _vm.scope.object_id,
                  callback: function($$v) {
                    _vm.$set(_vm.scope, "object_id", $$v)
                  },
                  expression: "scope.object_id"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("FeaturesCheckboxes", {
        ref: "featuresCheckboxes",
        attrs: { object: _vm.scope }
      }),
      _c("div", { staticClass: "flex flex-row gap-4 mt-12" }, [
        _c(
          "button",
          {
            staticClass: "sk-btn sk-btn-primary rounded-lg btn-border",
            on: { click: _vm.handleCancel }
          },
          [_vm._v(" " + _vm._s(_vm.$t("apps.modals.cancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "sk-btn sk-btn-primary rounded-lg",
            on: { click: _vm.handleSave }
          },
          [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }